function openPopupForm(markPopupformId, prodId, prodTitle, event)
{
    event.stopPropagation();
    $.get("/popupforms/getFormById/"+markPopupformId+"/", function(popupFormContent)
    {
        var popupFormHTML = $('<div title="" class="formDialog">').appendTo('body')
                            .html(popupFormContent);

        var sendUserInfo = function()
        {
            fio = $(".formDialog input[name='name']").val();
            phone = $(".formDialog input[name='phone']").val();
            email = $(".formDialog input[name='email']").val();
            comment = $(".formDialog input[name='comment']").val();

            $.post('/account/sendUserInfo/',
            {
                'prod_id':prodId,
                'prod_title':prodTitle,
                'user_fio': fio,
                'user_comment': comment,
                'user_phone': phone,
                'user_email': email
            });
            dialog.dialog( "close" );
        }

        var dialog = popupFormHTML.dialog(
        {
            autoOpen: false,
            height: 430,
            width: 460,
            modal: true,
            open: function(event, ui)
            {
                $('.ui-widget-overlay').bind('click', function(){ dialog.dialog('close'); });  // for close with click outside
            }
        });
        $(".ui-dialog-titlebar").hide(); // remove the title bar

        dialog.find("button[type='submit']").on("click", function (event)
        {
            event.preventDefault();
            sendUserInfo();
        });

        dialog.dialog( "open" );
    })
}
