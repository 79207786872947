function openInNewTab(url)
{
  var win = window.open(url, '_blank');
  win.focus();
}

/*----------  button up  ----------*/
$(function ()
{
    $(window).scroll(function ()
    {
        if ($(this).scrollTop() > 300) $('a#move_up').fadeIn(400);
        else $('a#move_up').fadeOut(600);
    });

    $('a#move_up').click(function ()
    {
        $('body,html').animate({ scrollTop: 0 }, 800);
        return false;
    });
});