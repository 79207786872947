var Cart;

$(function(){
    var buyingBox = null;

    // creating mobile box for show buying
    buyingBox = $("<img />",
    {
        src: "/images/design/box.png",
        css: {
            // opacity: 0,
            display: 'none',
            position: 'absolute',
            top: '-100px',
            left: '-100px'
        }
    }).appendTo(document.body);

    Cart = function()
    {
        /*_*/
    }

    Cart.updateInfo = function(total_quantity, total_cost)
    {
        /* in hat */
        $("span#cartQuantity")[0].innerHTML = total_quantity;
        $("span#cartInfoMessage")[0].innerHTML = total_cost;

        tableTotalQuantity = $("#cartContent #total_quantity")[0];
        tableTotalCost = $("#cartContent #total_cost")[0];

        /* Если есть таблица с содержимым корзины, */
        if (tableTotalQuantity != undefined && tableTotalCost != undefined) {
            /* То обновляем и её строчку с "Итого" */
            tableTotalQuantity.innerHTML = total_quantity;
            tableTotalCost.innerHTML = total_cost;
        }
    }

    Cart.showBuying = function(e)
    {
        /*get cart green indicator position*/
        var cartIndicatorNode = (cartIndicator = $("#cartQuantity"), cartIndicator[0]);
        var cartIndicatorInfo = cartIndicator.offset();

        /* placing box & highlighted indicator */
        var target = $(e.target ? e.target : /* stupid ie */ e.srcElement);
        var targetPos = target.offset();
        //console.info('target pos: ', targetPos);

        buyingBox.css({
            position: "absolute",
            top: targetPos.top + "px",
            left: targetPos.left + "px"
        });

        buyingBox.fadeIn();

        buyingBox.animate(
        {
            left: cartIndicatorInfo.left*1+cartIndicator.width()/2,
            top: cartIndicatorInfo.top*1+ cartIndicator.height()/2,
        }, 1500, function()
            {
                buyingBox.fadeOut();
            });
    }

    Cart.cartAdd = function(productId, forRent)
    {
        //for iMedia
        ga('send', 'event', 'product', 'add', 'product id - '+productId);
        yaCounter13638658.reachGoal('product_add');

        if (forRent)
            var rentCond = "/1/forRent";
        else
            var rentCond = "";
        $.getJSON("/ajax/cart/add/" + (+new Date()) + "/" + productId + rentCond+"/",
            function(cartInfo)
            {
              Cart.updateInfo(cartInfo.total_quantity, cartInfo.total_cost);
            })
        .fail(function(error){ console.info("Adding to cart failed!", error)});
    }

    Cart.plural = function(num, form1, form2, form3)
    {
        if(num > 100) num = num % 100;
        if(num > 20) num = num % 10;

        switch(num) {
            case(1): return form1;
            case(2):
            case(3):
            case(4): return form2;
            default: return form3;
        }
    }
});
