// object Cart had not time to load itself at the time to be called
function buy(event, itemId, forRent)
{
    if (Cart)
    {
        Cart.showBuying(event);
        Cart.cartAdd(itemId, forRent);
    } else
        setTimeout(buy.bind(true, event, itemId, forRent), 50);
}

/*  updating indicator in header  */
$(function()
{
    $.getJSON("/ajax/cart/cartInfo/" + (+new Date()) + "/", function(cartInfo)
    {
        Cart.updateInfo(cartInfo.total_quantity, cartInfo.total_cost);
    }).fail(function(error)
    {
        console.log("An error occurred:", error);
    })
})

/* Comparing */

function loadCookieForComparing()
{
    var compareIdsStr = Cookies.get('stankomasch_compare');
    if (compareIdsStr)
        return JSON.parse(compareIdsStr);
    else
        return [];
}

function addToCompareCookie(prodId, page)
{
    var compareProdIds = loadCookieForComparing();

    /* check doubles */
    for (var i = 0; i < compareProdIds.length; i++)
    {
        if (compareProdIds[i] == prodId)
        {
            delFromCompareCookie(prodId, page);
            return;
        }
    }

    compareProdIds.push(prodId);
    Cookies.set('stankomasch_compare', JSON.stringify(compareProdIds), {expires: 6});

    /* show modal on 10 seconds */
    $('#compareModal').fadeIn(400);
    setTimeout(function(){
        $('#compareModal').fadeOut(600);
    }, 5000);

    highlightProd(prodId, page);
    updateCompareBox();
}

function delFromCompareCookie(prodId, page)
{
    var compareProdIds = loadCookieForComparing();

    compareProdIds = compareProdIds.filter(function(item){ return item !== prodId})
    Cookies.set('stankomasch_compare', JSON.stringify(compareProdIds), {expires: 6});

    deHighlightProd(prodId, page);
    updateCompareBox();
}

/* show compare block if there is cookie */
function updateCompareBox()
{
    var compareProdIds = loadCookieForComparing();

    var compareBox = document.getElementById("compareBox");

    if (compareProdIds.length > 0)
    {
        compareBox.style.display = "block";
        $("#compareBox .compareNum")[0].innerHTML = compareProdIds.length;
        $("#compareBox .compareNum")[0].style.background = "hsl(215, 45%, 40%)";
    } else
    {
        compareBox.style.display = "none";
        $("#compareBox .compareNum")[0].style.background = "none";
    }
}

loadCookieForComparing();
updateCompareBox();

function isProdCompared(prod)
{
    var compareProdIds = loadCookieForComparing();
    if (compareProdIds.indexOf(prod) == -1)
        return false
    else
        return true
}

function checkIfProdsCompared(prodsOnPage)
{
    for (var i=0; i<prodsOnPage.length; i++)
    {
        var pageProd = prodsOnPage[i];
        if (isProdCompared(pageProd))
        {
            highlightProd(pageProd, "category");
        }
    }
}

function checkIfProdCompared(pageProd)
{
    if (isProdCompared(pageProd))
        highlightProd(pageProd, "product")
}

function highlightProd(prodId, page)
{
    if (page == "product")
    {
        $("#compareBtn").addClass('whiteBtnRed').removeClass("whiteBtnGrey");
        $("#compareBtn span span").text('В сравнении');
    } else if (page == "category")
    {
        var prSelector = '#compareLink_'+prodId;
        $(prSelector).addClass('mainRed').text('В сравнении');
    }
}

function deHighlightProd(prodId, page)
{
    if (page == "product")
    {
        $("#compareBtn").addClass('whiteBtnGrey').removeClass("whiteBtnRed");
        $("#compareBtn span span").text('К сравнению');
    } else if (page == "category")
    {
        var prSelector = '#compareLink_'+prodId;
        $(prSelector).removeClass('mainRed').text('К сравнению');
    }
}