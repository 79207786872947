var openRegionDialog, selectRegion, closeRegionDialog;

$(function()
{
    var regionLink, regionDialog, closeDialog, location, confirmRegion;

    openRegionDialog = function(shouldConfirmRegion) {
        if (shouldConfirmRegion == true)
            confirmRegion = true;

        regionDialog.style.display = 'block';
        regionDialog.style.left = '50%';
        var w = -regionDialog.clientWidth / 2 + 180;
        regionDialog.style.marginLeft = w + 'px';
        location = window.location.href;
        
        if (confirmRegion == true) 
            Cookies.set('region', 'null', {expires: 15});

        /*
        var href = window.location.href;
        if (href.substr(href.length - 1, 1) == '#') {
            return;
        }
        window.location += '#';
        */
        return false;
    }

    selectRegion = function(regionId) {
        if (confirmRegion == true) 
            confirmRegion = false;

        closeRegionDialog(null, false);

        $.get('/ajax/selectRegion/' + regionId + '/' + (+new Date()) + '/', function() 
        {
                window.location = location;
        });
    }

    closeRegionDialog = function(event, changeLocation) {
        if (changeLocation == undefined) 
            changeLocation = true;

        regionDialog.style.display = 'none';
        if (changeLocation == true) {
            window.location += '#';
            window.location = location;
        }

        if (event != null)
            event.preventDefault();

        return false;
    }

    $.get('/ajax/makeRegionDialog/', function(regionHtml)
    {
        regionDialog = $('.regionDialog')[0];
        
        regionDialog.innerHTML = regionHtml;

        regionLink = $('.regionLink')[0];
        closeDialog = $('.regionDialog .dialogClose')[0];

        $(regionLink).click(openRegionDialog);
        $(closeDialog).click(closeRegionDialog);
        $(window).click(function(event) 
        {
            if (event.target == regionLink ||
                $.contains(regionDialog, event.target)) {
                return false;
            }

            closeRegionDialog(null, false);
        })
    });
})
